import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.isSelected
      ? theme.palette.text.primary
      : props?.color ?? "#a5a5a5",
  }),
}));

export function Verified(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon viewBox="0 0 15 15" fontSize="inherit">
      <path
        className={classes.cls1}
        d="M6.5,2A2.262,2.262,0,0,0,4.25,4.25V12.5H2v2.25A2.261,2.261,0,0,0,4.25,17h7.5A2.261,2.261,0,0,0,14,14.75V6.5h3V4.25A2.261,2.261,0,0,0,14.75,2Zm0,1.5h6.141a2.2,2.2,0,0,0-.141.75v10.5a.737.737,0,0,1-.74.748V15.49a.74.74,0,0,1-.76-.74L10.98,12.5l-.73.007V12.5H5.75V4.25A.738.738,0,0,1,6.5,3.5Zm8.25,0a.738.738,0,0,1,.75.75V5H14V4.25A.738.738,0,0,1,14.75,3.5Zm-4.1,3.038-1.9,1.9L7.612,7.3,6.551,8.362l2.2,2.2L11.712,7.6ZM3.5,14H9.493l.007.769h0a2.2,2.2,0,0,0,.145.73H4.25a.738.738,0,0,1-.75-.75Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
}

Verified.propTypes = {
  color: PropTypes.string,
};
