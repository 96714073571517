import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.isSelected
      ? theme.palette.text.primary
      : props?.color ?? "#a5a5a5",
  }),
}));

export function Investigated(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon viewBox="0 0 13.385 13.383" fontSize="inherit">
      <path
        className={classes.cls1}
        d="M24.375,17.16a.561.561,0,0,0-.653-.445,5.38,5.38,0,0,0-1.1.347V13.513a1.4,1.4,0,0,0-1.393-1.393h-3.86v-.563a.557.557,0,1,0-1.115,0v.56H12.393A1.4,1.4,0,0,0,11,13.511V22.99a1.4,1.4,0,0,0,1.393,1.393h8.837a1.4,1.4,0,0,0,1.393-1.393V18.3a4.6,4.6,0,0,1,1.306-.489.553.553,0,0,0,.445-.65Zm-5.01-3.928-.4.789h-4.3l-.4-.789Zm2.147,9.761a.28.28,0,0,1-.279.279h-8.84a.28.28,0,0,1-.279-.279V13.513a.28.28,0,0,1,.279-.279h.617l.809,1.6a.556.556,0,0,0,.5.306H19.3a.556.556,0,0,0,.5-.306l.809-1.6h.617a.28.28,0,0,1,.279.279v4.161a16.569,16.569,0,0,0-3.491,3.256l-.781-.921a.558.558,0,1,0-.852.721l1.229,1.442a.558.558,0,0,0,.426.2h.019a.554.554,0,0,0,.429-.227,17.722,17.722,0,0,1,3.024-3.109v3.958Z"
        transform="translate(-11 -11)"
      />
    </SvgIcon>
  );
}

Investigated.propTypes = {
  color: PropTypes.string,
};
