import "./App.css";
// import Topnavbar from "./components/navbar/topnavbar/TopNavbar";
// import ManageFormsParent from "./screens/manageForms/index";

import AppAuth from "./App.auth";
import AppDrawer from "./App.drawer";
import { AppTheme } from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";

import { store as UIstore } from "form-binders";
import { Provider } from "react-redux";

import AppBackdrop from "./App.backdrop";
import RouterApp from "./router";
import AppDialog from "./App.dialog";
import { CssBaseline } from "@material-ui/core";

function App() {
  return (
    <Provider store={UIstore}>
      <AppAuth>
        <AppTheme>
          <AppErrorBoundary>
            <CssBaseline />
            <AppAlert>
              <AppDialog>
                <AppBackdrop>
                  <AppDrawer>
                    <RouterApp />
                  </AppDrawer>
                </AppBackdrop>
              </AppDialog>
            </AppAlert>
          </AppErrorBoundary>
        </AppTheme>
      </AppAuth>
    </Provider>
  );
}

export default App;
