import React from "react";
import { Routes } from "../router/routes";
import ViewStreamIcon from "@material-ui/icons/ViewStream";

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

export const NavBarArr = [
  {
    name: "Forms",
    link: Routes.forms,
    icon: <ViewStreamIcon />,
  },
];

export const FormComponents = {
  single_tab_select: "single_tab_select",
  multi_tab_select: "multi_tab_select",
  input_text: "input_text",
  input_textarea: "input_textarea",
  input_date_picker: "input_date_picker",
  multi_select_tag: "multi_select_tag",
  custom_multi_select_tag: "custom_multi_select_tag",
  select_modal_dropdown: "select_modal_dropdown",
  list_text: "list_text",
  input_number: "input_number",
  hours_minutes_range: "hours_minutes_range",
  grade: "grade",
  image_picker: "image_picker",
  switch_tab: "switch_tab",
  range_picker: "range_picker",
  popup_switch: "popup_switch",
  label: "label",
  note: "note",
  check_box: "check_box",
  radio_button: "radio_button",
  location: "location",
  custom_table: "custom_table",
  custom_table_2: "custom_table_2",
  mobile_no: "mobile_no",
  location_level: "location_level",
  date_of_birth: "date_of_birth",
};

export const crud = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
};

export const subNavBarArr = [
  {
    id: "disease_masters",
    prioriy: 2,
    name: "Manage Disease",
    url: Routes.list_diseases,
  },
  {
    id: "manage_forms",
    prioriy: 3,
    name: "Manage Forms",
    url: Routes.manage_forms,
  },
  {
    id: "country_masters",
    prioriy: 4,
    name: "Manage Countries",
    url: Routes.list_countries,
  },
  {
    id: "manage_roles",
    prioriy: 5,
    name: "Manage Roles",
    url: Routes.roles,
  },
  {
    id: "manage_user_groups",
    prioriy: 6,
    name: "Manage User",
    url: Routes.users,
  },
  {
    id: "notify",
    prioriy: 7,
    name: "Notification",
    url: Routes.notify,
  },
  {
    id: "notification",
    prioriy: 8,
    name: "Notification Registry",
    url: Routes.notifications,
  },
  {
    id: "Caseverification",
    prioriy: 9,
    name: "Case Verification",
    url: Routes.verifications,
  },
  {
    id: "investigation",
    prioriy: 11,
    name: "Investigation",
    url: Routes.investigations,
  },
  {
    id: "caseRegistration",
    prioriy: 10,
    name: "Case Registrations",
    url: Routes.case_registration,
  },
  {
    id: "powerLens",
    prioriy: 12,
    name: "Power Lens",
    url: Routes.power_lens,
  },
  {
    id: "r3Menus",
    prioriy: 13,
    name: "R3",
    url: Routes.r3,
  },
  {
    id: "managePatients",
    prioriy: 14,
    name: "Manage Patients",
    url: Routes.manage_patients,
  },
  {
    id: "dashboard",
    prioriy: 1,
    name: "Dashboard",
    url: Routes.dashboard_page,
  },
  {
    id: "home",
    prioriy: 0,
    name: "Home",
    url: Routes.home_page,
  },
];

export const configuration_data = [
  {
    id: "home",
    name: "Home",
  },
  {
    id: "disease_masters",
    name: "Manage Disease",
  },
  {
    id: "country_masters",
    name: "Manage Countries",
  },
  {
    id: "manage_forms",
    name: "Manage Forms",
  },
  {
    id: "manage_roles",
    name: "Manage Roles",
  },
  {
    id: "manage_user_groups",
    name: "Manage User",
  },
  {
    id: "dashboard",
    name: "Dashboard",
  },
  {
    id: "managePatients",
    name: "Manage Patients",
  },
];

export const worFlow_data = [
  {
    id: "notify",
    name: "Notify",
  },
  {
    id: "notification",
    name: "Notification",
  },
  {
    id: "Caseverification",
    name: "Case Verification",
  },
  {
    id: "investigation",
    name: "Investigation",
  },
  {
    id: "caseRegistration",
    name: "Case Registration",
  },
  {
    id: "powerLens",
    name: "Power Lens",
  },
  {
    id: "r3Menus",
    name: "R3",
  },
];

export const DependencyType = [
  {
    value: "form",
    label: "Form",
  },
  {
    value: "section",
    label: "Sections",
  },
  {
    value: "question",
    label: "Question",
  },
];

export const notifyTypeBesedOnEntryStatus = {
  notify: {
    1: "1",
    9: "2",
  },
  verify: {
    2: "6",
    3: "3",
    10: "7",
    11: "8",
  },
  register: {
    7: "9",
    8: "4",
    10: "10",
    11: "11",
  },
  investigate: {
    5: "12",
    6: "5",
  },
};

export const personalTitles = [
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Miss.",
    label: "Miss.",
  },
  {
    value: "Ms.",
    label: "Ms.",
  },
];

export const genderData = [
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Others",
    label: "Others",
  },
];
