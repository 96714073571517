import React from "react";
import {
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  header: {
    paddingLeft: 8,
  },
  textFieldParent: {
    padding: 8,
    paddingRight: 10,
    paddingBottom: 16,
  },
}));

export default function SkipComponent(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid>
          <Typography variant={"body1"}>
            {JSON.stringify(props?.indexOfno)
              ? JSON.stringify(props?.indexOfno) + ". "
              : ""}
            {"Smart Skip"}
          </Typography>
        </Grid>

        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={props?.data?.required ?? false}
                onChange={() =>
                  props.changeSkipComponent(props?.data?.required ?? false)
                }
                name="checkedA"
              />
            }
            label="Required"
          />
        </Grid>
      </Grid>

      {props.data.required && (
        <div className={classes.textFieldParent}>
          <TextField
            onChange={(e) => props.ChangeSkipQuestion(e)}
            value={props?.data?.label ?? ""}
            fullWidth
            label="Your Smart Skip Question?"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={props?.data?.error?.labelError ?? false}
            helperText={
              props?.data?.error?.labelError
                ? "Please enter a letter greater than two"
                : ""
            }
          />
        </div>
      )}
    </div>
  );
}
