import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  textRouter: {},
}));

const withSubNavBar = (Component) => (props) => {
  const classes = useStyles({ props });

  return (
    <div className={classes.root}>
      {/* Content */}
      <div className={classes.content}>
        <Component {...props}>{props.children} </Component>
      </div>
    </div>
  );
};

export default withSubNavBar;
