// The private route check wheather the user is logged in or not and also check
//   wheather the request route from the user is accessible to them or not using the Access(role,path)
//   function then allow to the particular route or else it will redirect to login page.

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "./routes";
import { useSelector } from "react-redux";
//import { Access } from "./access";

const PrivateRoute = ({ children, ...rest }) => {
  const { data } = useSelector((state) => state?.authSlice?.login);

  const isAuthenticated = (router) => {
    return true;
  };

  return (
    <Route
      {...rest}
      render={(_) =>
        isAuthenticated(_) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: _?.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
