import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "0px",
    height: "100%",
    width: "100%",
    // "& .MuiInputBase-root": {
    //   width: "100% !important",
    //   height: "100%",
    // },
    "& .MuiOutlinedInput-root": {
      paddingRight: "20px !important",
    },
  },
  label: {
    color: "rgba(146, 151, 165, 1)",
    textTransform: "uppercase",
    margin: "0px 8px",
  },
  autocomplete: {
    "& fieldset": {
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: "0px 9px",
    },
  },
}));

const getValue = (value, options) => {
  if (value && options?.length > 0) {
    return options.filter((val) => val.value === value)?.[0];
  } else {
    return value;
  }
};

export const SimpleSelect = (props) => {
  const givemeValueLabel = (option) => {
    return `${option?.value} (${option?.label})`;
  };
  const classes = useStyles();
  return (
    <div className={classes.margin}>
      <Autocomplete
        placeholder={props.placeholder ?? ""}
        id={props.idSelect}
        options={props?.options ? props?.options : []}
        getOptionLabel={(option) => givemeValueLabel(option)}
        disabled={props.disabled}
        onChange={(event, value) => props.handleChangeSelect(value?.value)}
        defaultValue={getValue(props?.value, props?.options)}
        className={classes.autocomplete}
        // disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            placeholder={props.placeholder ?? ""}
            // onChange={props.handleChangeSelect}
            // value={getValue(props?.valuePhoneCode, props?.options)}
            // value={getValue(props?.valuePhoneCode, props?.options)}
            autoComplete="karthik"
          />
        )}
      />
    </div>
  );
};
