import React from "react";
import { ListForms } from "./manageForms";
import { withNavBars, withSubNavBar } from "../../HOCs";
import { withStyles } from "@material-ui/core";
import { actions } from "form-binders";
import { connect } from "react-redux";
import { giveMeRoleDataSecurity } from "../../utils";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  DrawerContext,
  DialogContext,
  BackdropContext,
  AlertContext,
} from "../../contexts";

import Config from "../../config";

const styles = (theme) => ({
  root: {},
});

// const { UPSERT_FORM } = actions;
class ManageFormsParent extends React.Component {
  static contextType = BackdropContext;
  constructor(props) {
    super(props);
    this.state = {
      sort: "all",
      projectId: "",
      clientId: "",
    };
  }

  handleSortChange = (sort) => {
    this.setState(
      {
        sort,
      },
      () => {
        this.getForms();
      }
    );
  };

  componentDidMount() {
    // localStorage.removeItem("metadataId");
    // localStorage.removeItem("projectName");
    // localStorage.removeItem("projectId");
    // localStorage.removeItem("clientId");
    // localStorage.setItem("metadataId", null);
    // localStorage.setItem("projectName", null);

    this.getFormTypes();
    // this.getProjectDetails();
    this.getForms();
  }

  // clearlocal = () => {

  // };

  // getProjectDetails = async () => {

  // };

  getFormTypes = () => {
    this.props.GET_ALL_FORM_TYPES();
  };

  getForms = async (searchKey = "", created_at = false) => {
    debugger;

    this.context.setBackDrop({
      open: true,
      message: "loading",
    });
    if (window.location.search && window.location.search.length > 0) {
      const params = await new URLSearchParams(window.location.search);
      // let metdaId = localStorage.getItem("metadataId");
      // if(metdaId){
      sessionStorage.setItem("metadataId", params.get("metadata_id"));
      sessionStorage.setItem("projectName", params.get("projectName"));
    }
    await axios
      .post(`${Config.arango_api_url}/read_documents`, {
        db_name: `${Config.database}`,
        entity: "projectvstools",
        filter: `projectvstools.metadataid=='${sessionStorage.getItem(
          "metadataId"
        )}'`,
        return_fields:
          "{projectid:projectvstools.projectid,clientid:projectvstools.clientid}",
      })
      .then((response) => {
        console.log("ProjectDetails", response.data.result);
        this.setState({
          ...this.state,
          projectId: response?.data?.result[0]?.projectid,
          clientId: response?.data?.result[0]?.clientid,
        });

        sessionStorage.setItem(
          "projectId",
          response?.data?.result[0]?.projectid
        );
        sessionStorage.setItem("clientId", response?.data?.result[0]?.clientid);
        this.context.setBackDrop({
          open: false,
          message: "",
        });
      });
    const roleDataSecurity = giveMeRoleDataSecurity(this.props.userData);
    const createdBy = this.props.userData.user_profile_id;

    let sort = {
      sortField: "form_name",
      sortDirection: "ASC",
      diseases:
        roleDataSecurity?.disease?.[0] === "all"
          ? []
          : roleDataSecurity?.disease,
      countryIds:
        roleDataSecurity?.country?.[0] === "all"
          ? []
          : roleDataSecurity?.country,
      formIds:
        roleDataSecurity?.form?.[0] === "all" ? [] : roleDataSecurity?.form,
    };

    if (created_at) {
      sort.sortField = "updated_at";
      sort.sortDirection = "DESC";
    }

    let formTypeId = "";
    if (this.state.sort !== "all") {
      formTypeId = this.state.sort;
    }

    if (createdBy) {
      sort.created_by = createdBy;
    }

    // let formy = this.props.GET_ALL_FORMS({
    //   formName: searchKey.trim(),
    //   formTypeId,
    //   ...sort,
    // });

    // console.log(formy, "Forms..");
    // console.log("Local", localStorage.projectId);
    // console.log("LocalC", localStorage.clientId);

    this.props.GET_ALL_FORMS({
      formName: searchKey.trim(),
      clientId: sessionStorage.clientId,
      projectId: sessionStorage.projectId,
      formTypeId,
      ...sort,
    });
  };

  render() {
    const { classes, getAllForms } = this.props;
    console.log("AllForms", getAllForms);
    console.log(this.props, "this.props...");

    // const getAllForms = useSelector((state) => state?.formSlice?.getAllForms);
    // const mapStateToProps = (state) => {
    //   get
    // }

    return (
      <div className={classes.root}>
        <ListForms
          {...getAllForms}
          getForms={this.getForms}
          sort={this.state.sort}
          projectId={this.state.projectId}
          clientId={this.state.clientId}
          handleSortChange={this.handleSortChange}
        />
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   console.log(state.formSlice, "Form Slice States...");
//   return {
//     getAllForms: state?.formSlice?.getAllForms,
//     userData: state?.authSlice?.login?.data ?? {},
//   };
// };
const mapStateToProps = (state) => ({
  getAllForms: state?.formSlice?.getAllForms,
  userData: state?.authSlice?.login?.data ?? {},
});

export default connect(
  mapStateToProps,
  actions
)(withNavBars(withSubNavBar(withStyles(styles)(ManageFormsParent))));
