import React from "react";
import {
  makeStyles,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControlLabel-label": {
      textTransform: "capitalize",
    },
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));
const RadioButton = ({
  option = [],
  value = {},
  handleChange = false,
  direction = "column",
  heading,
  isReadonly = false,
  isrequired,
  errorValidation = {},
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body1">
        {heading}{" "}
        {isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          // aria-label="gender"
          // name="gender1"
          value={value.value}
          onChange={(value) =>
            handleChange({
              value: value.target.value,
              label: value.target.value,
            })
          }
          style={{
            flexDirection: direction,
          }}
        >
          {option &&
            option.map((data) => {
              return (
                <FormControlLabel
                  value={data.value}
                  control={
                    <Radio
                      color="primary"
                      disabled={(isReadonly || disabled) ?? false}
                    />
                  }
                  label={data.label}
                />
              );
            })}
        </RadioGroup>
      </FormControl>
      {errorValidation && errorValidation?.error && (
        <div>
          <Typography variant="caption" className={classes.errorText}>
            {errorValidation?.errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};
RadioButton.propTypes = {
  option: PropTypes.array, // EX: [{value: "", label: ""}],
  value: PropTypes.object, // Ex {value: "", label: ""}
  handleChange: PropTypes.func,
  direction: "row" | "column",
  heading: PropTypes.string,
};

export default RadioButton;
