import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import { SearchFilter } from "../../components/searchFilter/index";
import {
  DrawerContext,
  DialogContext,
  BackdropContext,
  AlertContext,
} from "../../contexts";

import { CardList, CardListLoader } from "../../components";

import { actions } from "form-binders";
import {
  DrawerProps,
  Message,
  AlertProps,
  ConvertToReactSelect,
} from "../../utils";
import { AddEditForm } from "./addEditForm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Routes } from "../../router/routes";
import axios from "axios";
import Config from "../../config";

// Store Action's list
const { UPSERT_FORM } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  search: {
    paddingBottom: theme.spacing(2),
    position: "sticky",
    top: 0,
    paddingTop: theme.spacing(2),
    //background: theme.palette.background.default,
    zIndex: theme.zIndex.appBar + 1,
  },
  progressBar: {
    position: "absolute",
    width: "100%",
  },
}));

export const ListForms = (props) => {
  console.log("ManageForms Props", props);
  const classes = useStyles();

  const formTypes = useSelector((state) => state?.formSlice?.getAllFormTypes);

  const drawerContext = React.useContext(DrawerContext);
  const dialogContext = React.useContext(DialogContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const history = useHistory();

  // Declaring Store dispatch
  const dispatch = useDispatch();

  let { data, loading, error } = props;

  const [copyValue, setCopyValue] = React.useState("");
  const [projectId, setProjectId] = React.useState("");
  const [clientId, setClientId] = React.useState("");

  React.useEffect(() => {
    // if (window.location.search && window.location.search.length > 0) {
    //   const params = new URLSearchParams(window.location.search);
    //   localStorage.setItem("metadataId", params.get("metadata_id"));
    //   localStorage.setItem("projectName", params.get("projectName"));
    // }
    // getProjectDetails();
    // async function setDBFunc() {
    //   let urlParams = new URLSearchParams(window.location.search);
    //   let metadataIdFromURL = urlParams?.get("metadata_id");
    //   console.log("metadataIdFromURL", metadataIdFromURL);
    //   let tokenFromUrl = urlParams.get("token");
    //   if (tokenFromUrl && metadataIdFromURL) {
    //     var data = JSON.stringify({
    //       db_name: "ATP_Metadata_Dev",
    //       entity: "projectvstools",
    //       filter: "projectvstools.metadataid == '" + metadataIdFromURL + "'",
    //       return_fields: "DOCUMENT(projectvstools.projectid)",
    //     });
    //     var config = {
    //       method: "post",
    //       url: "https://arangodbservice.dev.ainqaplatform.in/api/read_documents",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       data: data,
    //     };
    //     let res = await axios(config);
    //     let dbname =
    //       res?.data?.result?.[0]?.projectdbname ?? "ATP_Metadata_Dev";
    //     localStorage.setItem("DBNAME", dbname);
    //     localStorage.setItem("payload", tokenFromUrl);
    //   } else {
    //     let dbname = "ATP_Metadata_Dev";
    //     localStorage.setItem("DBNAME", dbname);
    //     localStorage.setItem("payload", tokenFromUrl);
    //   }
    // }
    // setDBFunc();
  }, []);

  console.log(
    Config.database,
    "Database Name",
    Config.arango_api_url,
    "API URL"
  );

  const getProjectDetails = async () => {
    backdrop.setBackDrop({
      open: true,
      message: "loading",
    });
    await axios
      .post(`${Config.arango_api_url}/read_documents`, {
        db_name: `${Config.database}`,
        entity: "projectvstools",
        filter: `projectvstools.metadataid=='${localStorage.getItem(
          "metadataId"
        )}'`,
        return_fields:
          "{projectid:projectvstools.projectid,clientid:projectvstools.clientid}",
      })
      .then((response) => {
        console.log("ProjectDetails", response.data.result);
        setProjectId(response?.data?.result[0]?.projectid);
        setClientId(response?.data?.result[0]?.clientid);
        backdrop.setBackDrop({
          open: false,
          message: "",
        });
      });
  };

  const setCopy = (value) => {
    setCopyValue(value);

    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Form ID Copied To ClipBoard !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  let previlages = {
    create: true,
    read: true,
    update: true,
    delete: true,
  };

  const onViewFormClicked = (data) => {
    debugger;
    history.push(Routes.manage_form_detail_parent + data?.form_id);
  };

  const onAddFormClicked = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditForm
          projectId={props?.projectId}
          clientId={props?.clientId}
          onActionCompleted={onActionCompleted}
        />
      ),
      onClose: () => onActionCompleted(),
    });
  };

  const onEditIconClicked = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditForm
          isEdit={true}
          editData={data}
          onActionCompleted={onActionCompleted}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };

  const onSearchChange = (value) => {
    if (value && value?.length % 3 === 0) {
      props.getForms(value);
    } else if (!value) {
      props.getForms();
    }
  };

  const sortOptionArray = [
    { value: "all", label: "All Form Types" },
    ...ConvertToReactSelect(formTypes?.data, "form_type_id", "form_type_name"),
  ];

  const onDeleteIconClicked = (data) => {
    debugger;
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: Message.manage_form_delete_title,
      body: Message.manage_form_delete_message,
      positiveBtn: Message.manage_form_delete_btn,
      negativeBtn: "Cancel",
      onOk: () => deleteCountry(data),
    });
  };

  const deleteCountry = (data) => {
    debugger;
    dialogContext.setDialog({ ...dialogContext, open: false });
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: Message.manage_form_delete,
    });

    Promise.resolve(
      dispatch(
        UPSERT_FORM({
          formId: data.form_id,
          isActive: false,
        })
      )
    ).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      props.getForms();

      let resStatus = res.payload.error;
      alert.setSnack({
        ...alert,
        open: true,
        severity: resStatus
          ? AlertProps.severity.error
          : AlertProps.severity.success,
        msg: resStatus
          ? "Form deletion Failed !"
          : "Form deleted Successfully !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    });
  };

  const onActionCompleted = () => {
    onDrawerClosed();
    props.getForms(document.getElementById("ippc_search_bar").value, true);
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  console.log(data, "Datas..");

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        {/* Search Filter Button Component */}
        <SearchFilter
          placeholder="Search form"
          buttonName="Add Form"
          onActionButton={onAddFormClicked}
          onSearchChange={onSearchChange}
          sortOptions={sortOptionArray}
          showSort={false}
          hideFilter={true}
          hideSearchBar={false}
          sort={props.sort}
          handleSortChange={props.handleSortChange}
          hideButton={false}
          /* loading={data?.length > 0 ? loading : false}
                   
                    buttonName="Add Form"
                    
                    hideFilter={true}
                    hideSearchBar={false}
                    
                    sort={props.sort}
                    
                    handleSortChange={props.handleSortChange}
                    hideButton={false} */
        />
      </div>

      {loading && !error && data?.length === 0 && <CardListLoader count={10} />}

      {data?.length > 0 && previlages.read && (
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          {data?.map((form, key) => (
            <Grid item key={key}>
              <CardList
                type="5"
                hideAvatar={true}
                title={form?.form_name}
                show_card={true}
                card_title={form?.form_type?.form_type_name ?? "Form Type"}
                // count={form?.notification_count ?? 0}
                // count_name={"Notifications"}
                // onCountClicked={() => onNotificationCountClicked(form)}
                // iconButton={false}
                hideCountButton={true}
                actionBtnName={"View Form"}
                actionBtnCopyName={"Copy Form ID"}
                copyValue={copyValue}
                onActionBtnClicked={() => onViewFormClicked(form)}
                onActionBtnCopyClicked={() => setCopy(form?.form_id)}
                onEditIconClicked={() => onEditIconClicked(form)}
                onDeleteIconClicked={() => onDeleteIconClicked(form)}
                hideActionBtn={!previlages.update}
                hideClickCopy={!previlages.update}
                isMoreBtn={previlages.update || previlages.delete}
                hideEditBtn={!previlages.update}
                hideDeleteBtn={!previlages.delete}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};
