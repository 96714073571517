import React from "react";
import { withRouter, Prompt } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
//import From from "./formGenerator";
import FormTree from "./formTree";
import { actions } from "form-binders";
import { connect } from "react-redux";
//import AgreeDailog from "./agreeDialog";
import { SubHeader, CaseDefinition } from "../../components";
import { Message } from "../../utils";
import { withAllContexts, withNavBars } from "../../HOCs";
// import { DrawerContext } from '../../contexts';
import { DrawerProps } from "../../utils";
import Form from "./formGenerator";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  gridArea: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class FormConfigure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "You are in profile page.",
      section_id: null,
      id_edit: false,
      openAgreeDailog: false,
      skipSectionId: null,
      section_detail_id: null,
      save: false,
      showPreview: false,
      set: false,
      render: false,
      formId: null,
      formTitle: "",
      is_sectionUpdate: false,
      formTemplate: null,
      templateFormAddStatus: {},
    };
  }

  onUnload = (e) => {
    e.preventDefault();
    if (this.state.id_edit) {
      e.returnValue = "";
    }
  };

  componentDidMount() {
    this.getFormDetail();
    this.getTemplateForms();
    this.getFormComponents();
    window.addEventListener("beforeunload", this.onUnload);
  }

  onBackBtnClicked = () => {
    this.props.history.goBack();
  };

  setTemplateFormFunction = (value) => {
    if (value !== null) {
      this.setState({ ...this.state, formTemplate: value });
    } else {
      this.setState({ ...this.state, formTemplate: null });
    }
  };

  setTemplateFormAddStatus = (val, type) => {
    if (val === null && type === null) {
      this.setState({
        ...this.state,
        templateFormAddStatus: {},
        formTemplate: null,
      });
    }
    if (type === "Form" && val !== null) {
      this.setState({ ...this.state, templateFormAddStatus: val });
    }
  };

  getSectionId = (data, parentSection) => {
    let section_detail_id = data?.section?.id;
    if (this.state.id_edit) {
      this.setState({
        ...this.state,
        formId: data.id,
        formTitle: data.section_name,
        section_detail_id,
        skipSectionId: data?.id ?? null,
        openAgreeDailog: true,
        selectedSection: data,
        parentSection: parentSection,
      });
    } else {
      this.setState({
        ...this.state,
        formId: data.id,
        formTitle: data.section_name,
        section_detail_id,
        section_id: data?.id ?? null,
        selectedSection: data,
        parentSection: parentSection,
      });
    }
  };

  skipIsedit = (type) => {
    if (type === "error") {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        save: false,
      });
      return false;
    }
    if (this.state.skipSectionId) {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        section_id: this.state.skipSectionId,
        skipSectionId: null,
        save: false,
        id_edit: false,
      });
    } else if (this.state.set) {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        //   skipSectionId: null,
        showPreview: true,
        save: false,
        id_edit: false,
      });
    } else {
      this.setState({
        ...this.state,
        openAgreeDailog: false,
        skipSectionId: null,
        save: false,
        id_edit: false,
      });
      setTimeout(() => {
        this.props.history.goBack();
      }, 1000);
    }
  };

  getFormDetail = () => {
    let id = this.props.match.params.id;
    let params = {
      form_id: id,
    };
    this.props.GET_FORM_DETAIL_BY_ID(params);
    this.props.GET_RENDER_JSON({ formId: id });
  };

  getTemplateForms = () => {
    this.props.GET_FORM_TEMPLATE();
  };

  getFormComponents = () => {
    this.props.GET_FORM_COMPONENTS();
  };

  getSectionComponents = () => {
    let section_detail_id = this.state?.section_id;
    this.setState({
      section_id: section_detail_id,
    });
  };

  checkFormEdit = (val, isSectionUpdate) => {
    this.setState(
      { ...this.state, id_edit: val, is_sectionUpdate: isSectionUpdate },
      () => {
        this.setState({
          is_sectionUpdate: false,
        });
      }
    );
  };

  goPreview = (val) => {
    if (this.state.id_edit) {
      this.setState({
        ...this.state,
        openAgreeDailog: val,
        set: true,
      });
    } else {
      this.setState({
        ...this.state,
        showPreview: val,
      });
    }
  };

  render() {
    const { classes } = this.props;

    const { data, loading } = this.props.details;

    const previewFormDetail = this.props.form_detail;

    const formTemplateData = this.props.getFormTemplate;
    console.log(formTemplateData, "formTempData");

    console.log(data, "Datasssss");

    return (
      <div className={classes.root}>
        <SubHeader
          title={loading ? Message.form_detail_loading : data?.title}
          subTitle={!loading ? `(${data?.formType?.type ?? "Form Type"})` : ""}
          onBackBtnClicked={this.onBackBtnClicked}
          onActionIconBtnClicked={this.onInfoClicked}
          showInfoButton
        />

        <Grid
          className={classes.gridArea}
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            {!this.state.is_sectionUpdate && (
              <FormTree
                selected_section_id={this.state.section_id}
                getSectionId={this.getSectionId}
                getTemplateForms={formTemplateData}
                id={this?.props?.match?.params?.id ?? null}
                formTemplateData={this.formTemplateData}
                setTemplateFormFunction={this.setTemplateFormFunction}
                setTemplateFormAddStatus={this.setTemplateFormAddStatus}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            <Form
              section_id={this?.state?.section_id ?? null}
              checkFormEdit={this.checkFormEdit}
              parentSection={this.state.parentSection}
              save={this.state.save}
              handleClose={this.skipIsedit}
              section_detail_id={this.state.section_detail_id}
              id={this?.props?.match?.params?.id ?? null}
              saveToMove={this.state.id_edit}
              preview={this.state.showPreview}
              goPreview={this.goPreview}
              reRender={this.state.render}
              previewFormDetail={previewFormDetail}
              formId={this.state.formId}
              formTitle={this.state.formTitle}
              selectedSection={this.state.selectedSection}
              templatedata={this.state?.formTemplate}
              templateFormAddStatus={this.state?.templateFormAddStatus}
              setTemplateFormAddStatus={this.setTemplateFormAddStatus}
              getTemplateFormsFunction={this.getTemplateForms}
              getSectionComponents={this.getSectionComponents}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.formSlice, "Form Slice States...");
  return {
    details: state?.formSlice?.getFormDetailById,
    formUpdate: state?.formconf?.upsert_question ?? { loading: false },
    form_detail: state?.renderSlice?.getRenderJson,
    auth: state?.authSlice?.login,
    getFormTemplate: state?.formSlice?.getFormTemplate,
  };
};

/* const mapStateToProps = (state) => ({
  details: state?.formSlice?.getFormDetailById,
  formUpdate: state?.formconf?.upsert_question ?? { loading: false },
  form_detail: state?.renderSlice?.getRenderJson,
  auth: state?.authSlice?.login,
  getFormTemplate: state?.formSlice?.getFormTemplate,
}); */

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(withNavBars(withRouter(withStyles(styles)(FormConfigure)))));
