import React from "react";
import TopNavBar from "./TopNavBar";
import { actions } from "form-binders";
import { connect } from "react-redux";

class TopNavbarParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.getNotifications();
    // setInterval(() => {
    //     console.log("Getting Notification Count...")
    //     this.getNotifications();
    // }, 30 * 1000);
  }

  getNotifications = () => {
    // this.props.GET_ALL_NOTIFICATIONS();
    // this.props.NOTIFICATIONS_COUNTS();
  };

  render() {
    return <TopNavBar />;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(TopNavbarParent);
