import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
}));

export const StringValidations = (props) => {
  const { validationState, onChange } = props;

  const classes = useStyles(props);

  const regexValidationData = [
    {
      value: "emailRegex",
      label: "Email Validation",
      message: "Enter a valid EmailId",
    },
  ];

  const stringValidation = (val) => {
    if (val === "minLength") {
      if (validationState?.maxLength?.length > 0) {
        if (
          parseInt(validationState?.maxLength) >
          parseInt(validationState?.minLength) + 1
        ) {
          return "";
        } else {
          return `Number should be less than "${parseInt(
            validationState?.maxLength - 1
          )}"`;
        }
      }
    } else {
      if (validationState?.minLength?.length > 0) {
        if (
          parseInt(validationState?.maxLength) >
          parseInt(validationState?.minLength) + 1
        ) {
          return "";
        } else {
          return `Number should be greater than "${
            parseInt(validationState?.minLength) + 1
          }"`;
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextField
            value={validationState?.maxLength ?? ""}
            onChange={(e) => onChange(e.target.value, "maxLength")}
            fullWidth
            label="Max Length"
            id="stringValidationStateMaxLength"
            variant="outlined"
            size="small"
            type="number"
            // error={val?.error?.labelError ?? false}
            helperText={validationState?.maxLength ? stringValidation() : ""}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextField
            value={validationState?.minLength ?? ""}
            onChange={(e) => onChange(e.target.value, "minLength")}
            fullWidth
            label="Min Length"
            id="stringValidationStateMinLength"
            variant="outlined"
            size="small"
            type="number"
            // error={val?.error?.labelError ?? false}
            helperText={
              validationState?.minLength ? stringValidation("minLength") : ""
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Autocomplete
            // className={classes.componentList}
            onChange={(e, newValue) => onChange(newValue, "regexValidation")}
            value={validationState?.regexValidation ?? ""}
            options={regexValidationData}
            getOptionLabel={(option) => option?.label ?? ""}
            clearOnEscape
            renderInput={(params) => (
              <TextField
                {...params}
                // className={classes.selectComponentList}
                size="small"
                placeholder={"Regex Validation"}
                fullWidth
                margin={"dense"}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default StringValidations;
