import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cls1: (props) => ({
    fill: props.isSelected
      ? theme.palette.text.primary
      : props?.color ?? "#a5a5a5",
  }),
}));

export function ForInvestigation(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon viewBox="0 0 13.5 15" fontSize="inherit">
      <path
        className={classes.cls1}
        d="M15,2.5H11.862a2.237,2.237,0,0,0-4.224,0H4.5A1.5,1.5,0,0,0,3,4V14.5A1.5,1.5,0,0,0,4.5,16H15a1.5,1.5,0,0,0,1.5-1.5V4A1.5,1.5,0,0,0,15,2.5ZM7.5,12.25H6v-1.5H7.5ZM6.75,8.875A1.125,1.125,0,1,1,7.875,7.75,1.125,1.125,0,0,1,6.75,8.875Zm3-6.375A.75.75,0,1,1,9,3.25.75.75,0,0,1,9.75,2.5Zm3.75,9.75H9v-1.5h4.5Zm0-3.75H9V7h4.5Zm1.5,6H4.5V4H15Z"
        transform="translate(-3 -1)"
      />
    </SvgIcon>
  );
}

ForInvestigation.propTypes = {
  color: PropTypes.string,
};
