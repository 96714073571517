import React from "react";

import {
  Button,
  makeStyles,
  Divider,
  Typography,
  Grid,
} from "@material-ui/core";

import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { BackdropContext, AlertContext } from "../../contexts";

//UI store
import { actions } from "form-binders";
import { Message, AlertProps } from "../../utils";
import { useSelector, useDispatch } from "react-redux";

// Store Action's list
const { UPSERT_FORM } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    padding: "10px 20px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    letterSpacing: 1,
    fontWeight: "500",
    lineHeight: "1.75",
  },
  formNameParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formTypeParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btn: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    letterSpacing: 1,
    fontWeight: "500",
    lineHeight: "1.75",
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectedTab: {
    backgroundColor: theme.palette.secondary.main + "!important",
    color: "#fff !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "12px",
  },
  selecteTab: {
    margin: "10px 16px 0px 0px",
    borderRadius: "6px !important",
    border: "1px solid rgba(0, 0, 0, 0.12) !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "12px",
  },
  lable: {
    margin: "auto 0",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    letterSpacing: 1,
    fontWeight: "500",
    lineHeight: "1.75",
  },
  labelRoot: {
    // fontSize: 30,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "15px",
    // "&$labelFocused": {
    //   color: "purple"
    // }
  },
}));

export const AddEditForm = (props) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    name: props?.editData?.form_name ?? "",
    id: props?.editData?.form_id ?? "",
    type_id: props?.editData?.form_type?.form_type_id ?? "",
    error: {
      name: false,
      type_id: false,
    },
  });

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const backdrop = React.useContext(BackdropContext);

  const alert = React.useContext(AlertContext);

  const upsertForm = useSelector((state) => state?.formSlice?.upsertForm);

  const formTypes = useSelector((state) => state?.formSlice?.getAllFormTypes);

  const onChangeInput = (e) => {
    setState({ ...state, name: e.target.value });
  };

  const onChangeSwitch = (e, newAlignment) => {
    setState({ ...state, type_id: newAlignment });
  };

  const validation = () => {
    if (state?.name?.length === 0) {
      state.error = {
        name: state?.name?.length === 0 ? true : false,
      };
      setState({ ...state });
    } else {
      return true;
    }
  };

  console.log("AddEditProps - ", props);

  const onSubmitForm = () => {
    const isValidation = validation();

    if (!isValidation) {
      return false;
    }
    console.log(state, "State");
    let __params = {
      formName: state?.name,
      formTypeId: state?.type_id,
      formId: state?.id,
      isActive: true,
      projectId: props.projectId,
      clientId: props.clientId,
    };

    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: props.isEdit
        ? Message.manage_form_update
        : Message.manage_form_add,
    });

    Promise.resolve(dispatch(UPSERT_FORM(__params))).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });

      let resStatus = res.payload.error;
      let statusMessage = props.isEdit
        ? "Form Updated Successfully !"
        : "Form Added Successfully !";
      let statusMessage2 = props.isEdit
        ? "Form Updated Failed!"
        : "Form Adding Failed !";
      if (res?.payload?.message === "Form Already Exist !") {
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: res?.payload?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        props.onActionCompleted(props.isEdit);

        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: resStatus ? statusMessage2 : statusMessage,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography className={classes.header}>Add Form</Typography>
          <Divider />
        </Grid>

        <Grid item className={classes.formNameParent}>
          <TextField
            onChange={(e) => onChangeInput(e)} //onChangeInput(e)
            value={state?.name ?? ""}
            fullWidth
            label="Form Name"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            error={state.error.name}
            helperText={
              state.error.name === true ? Message.requiredMessage : ""
            }
          />
        </Grid>

        <Grid item className={classes.formTypeParent}>
          <Typography variant="body2" className={classes.lable}>
            {"Form Type"}
          </Typography>
          <ToggleButtonGroup
            size={"small"}
            value={state?.type_id ?? null}
            exclusive
            onChange={onChangeSwitch}
            aria-label="Form Type"
          >
            {formTypes?.data.length > 0
              ? formTypes.data.map((formType, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      size={"small"}
                      value={formType?.form_type_id}
                      aria-label="left"
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                    >
                      {formType?.form_type_name}
                    </ToggleButton>
                  );
                })
              : "Something Went Wrong! Unable to get form types. Please try again later"}
          </ToggleButtonGroup>
          {state?.error?.type_id && (
            <Typography color="error" variant="caption">
              Select the form type...
            </Typography>
          )}
        </Grid>

        <Grid item className={classes.btnParent}>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            disabled={upsertForm?.loading}
            onClick={() => onSubmitForm()} //submitForm()
          >
            {props.isEdit
              ? upsertForm?.loading
                ? "Updating..."
                : "Update Form"
              : upsertForm?.loading
              ? "Adding..."
              : "Add Form"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
