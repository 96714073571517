import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
    "& .MuiFormHelperText-contained": {
      margin: 0,
      marginTop: 4,
      color: "red",
    },
  },
  labelStyle: {
    fontSize: 16,
  },
}));

const Input = (props) => {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <TextField
          type={props.type}
          id={props.id}
          // label={props.label}
          required={props.isrequired}
          variant={"outlined"}
          fullWidth
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ readOnly: props?.isReadonly ?? false }}
          disabled={props?.disabled ?? false}
          size="small"
          multiline={props.multiline}
          rows={5}
          rowsMax={10}
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value}
          // onBlur={validations}
          error={
            props?.errorValidation && props?.errorValidation?.error
              ? props?.errorValidation?.error
              : false
          }
          helperText={
            props?.errorValidation && props?.errorValidation?.errorMessage
              ? props?.errorValidation?.errorMessage
              : ""
          }
        />
      </>
    </div>
  );
};
Input.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
};
Input.defaultProps = {
  label: "label",
  //   variant: "outlined",
  multiline: false,
  type: "text",
};
export default Input;
