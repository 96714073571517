//Input Component

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { ToggleButtonComponent } from "../../toggleButton";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

const TapSelect = (props) => {
  const classes = useStyles();

  let toggleButtonArray = [];
  // let selectedData = [];

  //list data
  props.items &&
    props.items.map((item) => {
      if (item.value) {
        if (item.label) {
          return toggleButtonArray.push(item);
        }
      } else {
        return toggleButtonArray.push({
          value: item,
          label: item,
        });
      }
      return item;
    });

  //selected data array
  // props.values && props.values.map((value)=>{
  //   return selectedData.push(value)
  // })

  return (
    <div>
      <div style={{ display: "flex" }}>
        {
          <Typography variant="body1" className={classes.root} gutterBottom>
            {props.label}
          </Typography>
        }
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </div>
      <div>
        {toggleButtonArray?.length > 0 ? (
          props.multiple ? (
            //multiple select
            <ToggleButtonComponent
              disabled={props.isReadyOnly || props?.disabled}
              onSelected={props.onClick}
              toggleButtonData={toggleButtonArray}
              isSelected={props.value}
            />
          ) : (
            //single select
            <ToggleButtonComponent
              disabled={props.isReadyOnly || props?.disabled}
              onSelected={props.onClick}
              toggleButtonData={toggleButtonArray}
              isSelected={props.value}
              exclusive
            />
          )
        ) : (
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {props?.notFoundMsg ?? " - "}
          </Typography>
        )}
      </div>
      {props?.errorValidation && props?.errorValidation?.error && (
        <Typography variant="caption" className={classes.errorText}>
          {props?.errorValidation?.errorMessage}
        </Typography>
      )}
    </div>
  );
};
TapSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.array,
  multiple: PropTypes.bool,
  isReadyOnly: PropTypes.bool,
  isrequired: PropTypes.bool,
  notFoundMsg: PropTypes.string,
};
TapSelect.defaultProps = {
  label: "label",
  type: "single",
  multiple: false,
  isReadyOnly: false,
};
export default TapSelect;
