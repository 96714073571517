import React from "react";
import {
  Grid,
  Button,
  Typography,
  Paper,
  Avatar,
  Popover,
  List as MUList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// import { useHistory } from 'react-router-dom';
import {
  ForInvestigation,
  Investigated,
  Verified,
  Archived,
} from "../../assets";
import { Delete, MoreVert } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import { SwitchComponent } from "../switch";
export * from "./loading";
export * from "./cardListLoader";

const useStyles = makeStyles((theme) => ({
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  spaceBtw: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
  },
  grow: {
    flex: 1,
  },
  mobileViewResp: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
      paddingLeft: 12,
    },
  },
  itemSpacing: {
    padding: 10,
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "0px 15px 25px #110F4712",
    borderRadius: 10,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      padding: "12px 6px",
    },
  },
  iconTypoSpacing: {
    padding: "0px 10px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      // padding: 0,
      // paddingRight: 10
      flex: 1,
      justifyContent: "flex-end",
    },
  },
  buttonSpacing: {
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      // margin: "6px auto !important"
    },
  },
  iconView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    borderRadius: "50%",
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      marginRight: 5,
    },
  },
  IconButton: {
    padding: 8,
  },
  btn: {
    boxShadow: "0px 5px 23px #00000014",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 13,
    textTransform: "capitalize",
    letterSpacing: 1,
    "&:hover": {
      // background: '#45149B'
    },
  },
  bel: {
    fontSize: 17,
  },
  iconSpacing: {
    marginRight: 8,
    marginBottom: 4,
    display: "flex",
  },
  mobViewColumn: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  marginRight: {
    marginRight: 35,
    [theme.breakpoints.down("xs")]: {
      // flexWrap: 'nowrap',
      // width: '100%'
    },
  },
  badge: {
    marginLeft: theme.spacing(1),
    padding: "0px 8px",
    background: theme.palette.common.white,
    color: theme.palette.ternary.main,
    fontFamily: "ippc_extrabold !important",
    border: `0.2px solid ${theme.palette.ternary.main}`,
    borderRadius: 12,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      alignSelf: "flex-start",
      margin: "5px 0px",
    },
  },
  clickableCard: {
    cursor: "pointer",
  },
  spacingBtn: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    width: 1,
    height: 30,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    margin: "0px 20px",
  },
  listItemIcon: {
    minWidth: 35,
  },
  infoIcon: {
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },

  infoButton: {
    marginLeft: theme.spacing(1),
    padding: "0px 8px",
    background: theme.palette.common.white,
    color: theme.palette.ternary.main,
    fontFamily: "ippc_extrabold !important",
    border: `0.2px solid ${theme.palette.ternary.main}`,
    borderRadius: 12,
  },
  icon_I: {
    fill: theme.palette.text.secondary,
  },
  absPoisiton: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
  titleSubTitle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  justifyBtn: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
      width: "100%",
      padding: 10,
    },
  },
  justifyBtnMarginBottom: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      width: "100%",
      padding: 10,
    },
  },
  mobViewPaddingLeft: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 12,
    },
  },
  countPara: {
    [theme.breakpoints.up("xs")]: {
      minWidth: 180,
    },
  },
}));

export const CardList = (props) => {
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // console.log("Props CardList", props);

  return (
    <Grid item xs={12}>
      {/* Notification */}
      {props.type === "1" && (
        <Grid
          item
          xs={12}
          className={`${classes.rowCenter} ${classes.itemSpacing}`}
        >
          <div className={classes.grow}>
            <div className={classes.rowCenter}>
              <Paper elevation={2} className={classes.iconView}>
                <IconButton color="inherit">🌟</IconButton>
              </Paper>
              <Typography variant="body1">Covid 19 Case Registory</Typography>
            </div>
          </div>

          <div className={`${classes.rowCenter} `}>
            <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
              <div className={classes.iconSpacing}>
                <ForInvestigation />
              </div>
              <Typography variant="body1">08</Typography>
            </div>
            <div className={classes.buttonSpacing}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={props?.onCardClicked ?? false}
              >
                Notify
              </Button>
            </div>
          </div>
        </Grid>
      )}

      {/* Verification */}
      {props.type === "2" && (
        <Grid
          item
          xs={12}
          className={`${classes.rowCenter} ${classes.itemSpacing} ${classes.clickableCard}`}
        >
          <div
            className={`${classes.grow} ${classes.mobViewPaddingLeft}`}
            onClick={props?.onCardClicked ?? false}
          >
            <div className={classes.rowCenter}>
              {/* <Paper elevation={2} className={classes.iconView}>
                <IconButton color='inherit'>
                  🌟
                </IconButton>
              </Paper> */}
              <Typography variant="body1">{props.title}</Typography>
            </div>
          </div>

          <div className={`${classes.rowCenter} `}>
            {props.iconButton && (
              <div className={classes.infoIcon}>
                <IconButton onClick={props.onActionIconBtnClicked}>
                  <InfoOutlinedIcon className={classes.icon_I} />
                </IconButton>
              </div>
            )}

            <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
              <Tooltip arrow title={"For Verification"} placement="top">
                <Button onClick={() => props?.onCountClicked(0) ?? false}>
                  <div className={classes.iconSpacing}>
                    <ForInvestigation />
                  </div>
                  {props?.for_verification_count ?? 0}
                </Button>
              </Tooltip>
            </div>

            <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
              <Tooltip arrow title={"Verified"} placement="top">
                <Button onClick={() => props?.onCountClicked(1) ?? false}>
                  <div className={classes.iconSpacing}>
                    <Verified />
                  </div>
                  {props?.verified_count ?? 0}
                </Button>
              </Tooltip>
            </div>
          </div>
        </Grid>
      )}

      {/* Investigation */}
      {props.type === "3" && (
        <Grid
          item
          xs={12}
          className={`${classes.rowCenter} ${classes.itemSpacing} ${classes.clickableCard}`}
        >
          <div
            onClick={props?.onCardClicked ?? false}
            className={`${classes.grow} ${classes.mobileViewResp}`}
          >
            <div className={classes.rowCenter}>
              {/* <Paper elevation={2} className={classes.iconView}>
                <IconButton color='inherit'>
                  🌟
                  </IconButton>
              </Paper> */}
              <Typography variant="body1">{props?.title}</Typography>
            </div>
          </div>

          <div className={`${classes.rowCenter} ${classes.spaceBtw}`}>
            {props.iconButton && (
              <div className={classes.infoIcon}>
                <IconButton onClick={props.onActionIconBtnClicked}>
                  <InfoOutlinedIcon className={classes.icon_I} />
                </IconButton>
              </div>
            )}

            <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
              <Tooltip arrow title={"For Investigation"} placement="top">
                <Button onClick={() => props?.onCountClicked(0) ?? false}>
                  <div className={classes.iconSpacing}>
                    <ForInvestigation />
                  </div>
                  {props?.for_investigation_count ?? 0}
                </Button>
              </Tooltip>
            </div>

            <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
              <Tooltip arrow title={"Investigated"} placement="top">
                <Button onClick={() => props?.onCountClicked(1) ?? false}>
                  <div className={classes.iconSpacing}>
                    <Investigated />
                  </div>
                  {props?.investigated_count ?? 0}
                </Button>
              </Tooltip>
            </div>

            <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
              <Tooltip arrow title={"Verified"} placement="top">
                <Button onClick={() => props?.onCountClicked(2) ?? false}>
                  <div className={classes.iconSpacing}>
                    <Verified />
                  </div>
                  {props?.verified_count ?? 0}
                </Button>
              </Tooltip>
            </div>

            <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
              <Tooltip arrow title={"Archived"} placement="top">
                <Button onClick={() => props?.onCountClicked(3) ?? false}>
                  <div className={classes.iconSpacing}>
                    <Archived />
                  </div>
                  {props?.archived_count ?? 0}
                </Button>
              </Tooltip>
            </div>
          </div>
        </Grid>
      )}

      {/* Case Registration */}
      {props.type === "4" && (
        <Grid
          item
          xs={12}
          className={`${classes.rowCenter} ${classes.itemSpacing} ${classes.clickableCard}`}
        >
          <div
            onClick={props?.onCardClicked ?? false}
            className={`${classes.grow} ${classes.mobViewPaddingLeft}`}
          >
            <div className={classes.rowCenter}>
              {/* <Paper elevation={2} className={classes.iconView}>
                <IconButton color='inherit'>
                  🌟
                </IconButton>
              </Paper> */}
              <Typography variant="body1">{props.title}</Typography>
            </div>
          </div>

          {props.iconButton && (
            <div className={classes.infoIcon}>
              <IconButton onClick={props.onActionIconBtnClicked}>
                <InfoOutlinedIcon className={classes.icon_I} />
              </IconButton>
            </div>
          )}

          <div className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}>
            <Tooltip arrow title={props?.count_name} placement="top">
              <Button onClick={() => props?.onCountClicked(1) ?? false}>
                <div className={classes.iconSpacing}>
                  <ForInvestigation />
                </div>
                {props?.count ?? 0}
              </Button>
            </Tooltip>
          </div>
        </Grid>
      )}

      {props.type === "5" && (
        <Grid
          item
          xs={12}
          className={`${classes.rowCenter} ${classes.itemSpacing} ${classes.mobViewColumn}`}
        >
          <div className={`${classes.grow} ${classes.mobViewPaddingLeft}`}>
            <div className={`${classes.rowCenter}`}>
              {!props.hideAvatar && (
                <Avatar
                  style={{ marginRight: 16 }}
                  variant="rounded"
                  src={props.src}
                  alt={props.alt}
                >
                  {props.alt}
                </Avatar>
              )}

              <div>
                <Typography variant="body1" className={classes.titleSubTitle}>
                  {props.title}
                  {props?.show_card && (
                    <Typography variant={"caption"} className={classes.badge}>
                      {props.card_title}
                    </Typography>
                  )}
                </Typography>

                {props.show_sub_title && (
                  <Typography component="p" variant="caption">
                    {props.sub_title}
                  </Typography>
                )}
              </div>
            </div>
          </div>

          <div className={`${classes.rowCenter} ${classes.justifyBtn}`}>
            {props.iconButton && (
              <div className={classes.infoIcon}>
                <IconButton onClick={props.onActionIconBtnClicked}>
                  {/* <InfoIcon style={{color:"yellow"}}/> */}
                  <InfoOutlinedIcon className={classes.icon_I} />
                </IconButton>
              </div>
            )}

            {!props?.hideCountButton && (
              <div
                className={`${classes.rowCenter} ${classes.iconTypoSpacing}`}
              >
                <Tooltip arrow title={props?.count_name} placement="top">
                  <Button
                    className={classes.countPara}
                    onClick={() => props?.onCountClicked() ?? false}
                  >
                    <div className={classes.iconSpacing}>
                      <ForInvestigation />
                    </div>
                    {props?.count ?? 0}
                  </Button>
                </Tooltip>
              </div>
            )}

            {!props?.hideClickCopy && (
              <div className={classes.buttonSpacing}>
                <CopyToClipboard text={props?.copyValue}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.btn}
                    onClick={props.onActionBtnCopyClicked}
                  >
                    {props?.actionBtnCopyName ?? "Action"}
                  </Button>
                </CopyToClipboard>
              </div>
            )}

            {!props?.hideActionBtn && (
              <div className={classes.buttonSpacing}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.btn}
                  onClick={props.onActionBtnClicked}
                >
                  {props?.actionBtnName ?? "Action"}
                </Button>
              </div>
            )}

            {props?.showManageLocationButton && (
              <div className={classes.buttonSpacing}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.btn}
                  onClick={props.manageLocationBtnAction}
                >
                  {props?.manageLocationBtnName ?? "Action"}
                </Button>
              </div>
            )}

            {props?.showManagePatientDetailButton && (
              <div className={classes.buttonSpacing}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.btn}
                  onClick={props.managePatientDetailBtnAction}
                >
                  {props?.managePatientDetailBtnName ?? "Action"}
                </Button>
              </div>
            )}
          </div>

          {props.isMoreBtn && (
            <div className={classes.absPoisiton}>
              <IconButton aria-describedby={id} onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MUList dense>
                  {/* Edit Icon */}
                  {!props?.hideEditBtn && (
                    <ListItem
                      dense
                      button
                      onClick={() => {
                        handleClose();
                        props.onEditIconClicked(props);
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <EditIcon htmlColor="rgba(69, 20, 155, 1)" />
                      </ListItemIcon>
                      <ListItemText primary="Edit" />
                    </ListItem>
                  )}

                  {/* Delete Icon */}
                  {!props?.hideDeleteBtn && (
                    <ListItem
                      dense
                      button
                      onClick={() => {
                        handleClose();
                        props.onDeleteIconClicked(props);
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <Delete htmlColor="rgba(69, 20, 155, 1)" />
                      </ListItemIcon>
                      <ListItemText primary="Delete" />
                    </ListItem>
                  )}
                </MUList>
              </Popover>
            </div>
          )}
        </Grid>
      )}

      {props.type === "6" && (
        <Grid
          item
          xs={12}
          className={`${classes.rowCenter} ${classes.itemSpacing} ${classes.mobViewColumn}`}
        >
          <div className={`${classes.grow} ${classes.mobViewPaddingLeft}`}>
            <div className={`${classes.rowCenter}`}>
              {!props.hideAvatar && (
                <Avatar
                  style={{ marginRight: 16 }}
                  variant="rounded"
                  src={props.src}
                  alt={props.alt}
                >
                  {props.alt}
                </Avatar>
              )}

              <div>
                <div className={classes.rowCenter}>
                  <Typography variant="body1">{props.title}</Typography>

                  {props?.show_card && (
                    <Typography variant={"caption"} className={classes.badge}>
                      {props.card_title}
                    </Typography>
                  )}
                </div>

                <div className={classes.rowCenter}>
                  <Typography variant="caption">{props.sub_title}</Typography>

                  {props?.show_sub_card && (
                    <Typography variant={"caption"} className={classes.badge}>
                      {props.sub_card_title}
                    </Typography>
                  )}
                </div>
              </div>
              <div>{props?.divider && <div className={classes.divider} />}</div>
              <div>
                {props?.email_id && (
                  <Typography variant={"caption"}>{props.email_id}</Typography>
                )}
              </div>
            </div>
          </div>

          <div
            className={`${classes.rowCenter}  ${classes.justifyBtnMarginBottom}`}
          >
            <div>
              {props?.switch && (
                <SwitchComponent
                  checked={props?.checked}
                  onChange={props?.onChange}
                />
              )}
            </div>

            <div>
              {props?.privilegesButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.btn} ${classes.spacingBtn}`}
                  onClick={props.privilegesButtonAction ?? false}
                >
                  {props?.privilegesButtonName ?? "Action"}
                </Button>
              )}
              {props?.dataSecurityButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.btn} ${classes.spacingBtn}`}
                  onClick={props.dataSecurityButtonAction ?? false}
                >
                  {props?.dataSecurityButtonName ?? "Action"}
                </Button>
              )}
              {props?.manage_locationButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.btn} ${classes.spacingBtn}`}
                  onClick={props?.manageLocationButtonAction ?? false}
                >
                  {props?.manageLocationButtonName ?? "Action"}
                </Button>
              )}
              {props?.viewUserButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.btn} ${classes.spacingBtn}`}
                  onClick={props.viewUserButtonAction ?? false}
                >
                  {props?.viewUserButtonName ?? "Action"}
                </Button>
              )}
              {props?.mapRoleButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.btn} ${classes.spacingBtn}`}
                  onClick={props.mapRoleButtonAction ?? false}
                >
                  {props?.mapRoleButtonName ?? "Action"}
                </Button>
              )}
            </div>
          </div>
          {props.isMoreBtn && (
            <div className={classes.absPoisiton}>
              <IconButton aria-describedby={id} onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MUList dense>
                  {/* Edit Icon */}
                  {!props?.hideEditBtn && (
                    <ListItem
                      dense
                      button
                      onClick={() => {
                        handleClose();
                        props.onEditIconClicked(props);
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <EditIcon htmlColor="rgba(69, 20, 155, 1)" />
                      </ListItemIcon>
                      <ListItemText primary="Edit" />
                    </ListItem>
                  )}

                  {/* Delete Icon */}
                  {!props?.hideDeleteBtn && (
                    <ListItem
                      dense
                      button
                      onClick={() => {
                        handleClose();
                        props.onDeleteIconClicked(props);
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <Delete htmlColor="rgba(69, 20, 155, 1)" />
                      </ListItemIcon>
                      <ListItemText primary="Delete" />
                    </ListItem>
                  )}
                </MUList>
              </Popover>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

CardList.propTypes = {
  type: PropTypes.oneOf(["1", "2", "3", "4", "5"]),

  //type 3 and type 4 props
  onCardClicked: PropTypes.func,

  //type 2 props
  for_verification_count: PropTypes.number,
  verified_count: PropTypes.number,

  //type 3 props
  for_investigation_count: PropTypes.number,
  investigated_count: PropTypes.number,
  archived_count: PropTypes.number,

  //type 5 props
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  count_name: PropTypes.string,
  onCountClicked: PropTypes.func,
  actionBtnName: PropTypes.string,
  actionBtnCopyName: PropTypes.string,
  copyValue: PropTypes.string,
  onActionBtnClicked: PropTypes.func,
  onActionBtnCopyClicked: PropTypes.func,
  isMoreBtn: PropTypes.bool,
  onEditIconClicked: PropTypes.func,
  onDeleteIconClicked: PropTypes.func,
  hideActionBtn: PropTypes.bool,
  hideClickCopy: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  show_card: PropTypes.bool,
  card_title: PropTypes.string,
  show_sub_title: PropTypes.string,
  sub_title: PropTypes.string,
  hideCountButton: PropTypes.bool,

  showManageLocationButton: PropTypes.bool,
  manageLocationBtnName: PropTypes.string,
  manageLocationBtnAction: PropTypes.func,

  //type 6 props

  //already declared in proptypes five

  // src: PropTypes.string,
  // alt: PropTypes.string,
  // title: PropTypes.string,
  // isMoreBtn: PropTypes.bool,
  // show_card: PropTypes.bool,
  // card_title: PropTypes.string,
  // hideAvatar: PropTypes.bool,

  show_sub_card: PropTypes.bool,
  sub_card_title: PropTypes.string,
  divider: PropTypes.bool,
  email_id: PropTypes.string,
  switch: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  privilegesButton: PropTypes.bool,
  privilegesButtonAction: PropTypes.func,
  privilegesButtonName: PropTypes.string,
  dataSecurityButton: PropTypes.bool,
  dataSecurityButtonAction: PropTypes.func,
  dataSecurityButtonName: PropTypes.string,
  manage_locationButton: PropTypes.bool,
  manageLocationButtonAction: PropTypes.func,
  manageLocationButtonName: PropTypes.string,
  viewUserButton: PropTypes.bool,
  viewUserButtonAction: PropTypes.func,
  viewUserButtonName: PropTypes.string,
  mapRoleButton: PropTypes.bool,
  mapRoleButtonAction: PropTypes.string,
  mapRoleButtonName: PropTypes.string,
};

CardList.defaultProps = {
  type: "1",

  count: 0,
};
