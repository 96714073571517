// Sub Header with back button,title and subtitle.

import React from "react";
import {
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
//import { ToggleButtonComponent } from "../toggleButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MenuIcon from "@material-ui/icons/Menu";
//import { ArchivedDialog } from '../../components';
// store
import { actions } from "form-binders";
import { useDispatch } from "react-redux";
//import { Message, notifyTypeBesedOnEntryStatus } from '../../utils'

import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { Tooltip } from "@material-ui/core";

// Store Action's list
const { UPDATE_ENTRY_STATUS } = actions;

const useStyles = makeStyles((theme) => ({
  root: {},
  subTitle: {
    marginLeft: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  divider: {
    margin: 10,
  },
  viewSection: {
    padding: "5px 12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 8px #110F471A",
    border: "1px solid #110F471A",
    borderRadius: 7,
    // [theme.breakpoints.up('md')]: {
    //     display: 'none'
    // }
  },
  patientDetail: {
    marginLeft: 15,
    "& p": {
      display: "flex",
      alignItems: "center",
    },
    "& span": {
      fontSize: "1rem",
    },
    "& hr": {
      margin: 7,
      width: "1.5px",
      backgroundColor: "#000",
    },
  },
}));

export const SubHeader = ({
  hideBackBtn = false,
  hideTitle = false,
  hideSubTitle = false,
  title = "",
  subTitle = "",
  onBackBtnClicked = () => false,
  showToogleButton = false,
  toggleButtonData = [],
  selected = {},
  exclusive = false,
  onSelected = () => false,
  onActionIconBtnClicked = () => false,
  showInfoButton = false,
  showDivider = false,
  drawerButton = false,
  drawerButtonAction = () => false,
  statusUpdateData = {},
  statusPath = "",
  view,
  isReadyOnly,
  secName = "",
  redirectPath = "",
  getPatientDetails,
  showPatientDetails = false,
  onPatientInfoBtnClicked = () => false,
  // setView
}) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);

  // Declaring Store dispatch
  const dispatch = useDispatch();

  //show or hide dialog
  const [open, setOpen] = React.useState(false);

  const [selectedData, setSelectedData] = React.useState(selected ?? {});

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        // direction='column'
        // justify="center"
        alignItems="center"
      >
        <Grid item style={{ flex: 1 }}>
          <div className={classes.header}>
            {!hideBackBtn && (
              <Grid item>
                <IconButton
                  onClick={onBackBtnClicked}
                  style={{ padding: drawerButton }}
                >
                  <KeyboardBackspace htmlColor="rgba(17, 15, 71, 1)" />
                </IconButton>
              </Grid>
            )}

            {!hideTitle && (
              <Grid item>
                <Typography
                  style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
                  variant="body1"
                  color="textPrimary"
                >
                  {title}
                </Typography>
              </Grid>
            )}

            {showPatientDetails && (
              <Grid item className={classes.patientDetail}>
                <Typography variant="body1" color="textPrimary">
                  {getPatientDetails && getPatientDetails?.patient_name && (
                    <Typography variant="h6" color="textPrimary">
                      {`(${
                        getPatientDetails?.patient_name?.title?.label ?? ""
                      } ${getPatientDetails?.patient_name?.first_name ?? ""} ${
                        getPatientDetails?.patient_name?.last_name?.[0] ?? ""
                      })`}
                      <Tooltip title={"View Patient Details"}>
                        <IconButton onClick={onPatientInfoBtnClicked}>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  )}
                  {getPatientDetails && getPatientDetails?.icNumber && (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      style={{ marginLeft: 10 }}
                    >
                      {getPatientDetails?.icNumber ?? ""}
                    </Typography>
                  )}
                  {getPatientDetails && getPatientDetails?.passportNumber && (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <Typography variant="caption" color="textPrimary">
                        {getPatientDetails?.passportNumber ?? ""}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
            )}

            {/* {!hideSubTitle && <Grid item className={classes.subTitle}>
                        <Typography variant="caption">
                            {subTitle}
                        </Typography>
                    </Grid>} */}
          </div>
          {drawerButton && (
            <Button
              variant="text"
              className={classes.viewSection}
              onClick={drawerButtonAction}
              color="secondary"
            >
              <div style={{ marginRight: 5, display: "flex" }}>
                {" "}
                <MenuIcon />{" "}
              </div>
              View Sections
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
