import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  textField: {},
  labelStyle: {
    fontSize: 16,
  },
}));

const DateTime = (props) => {
  const classes = useStyles();

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const minimunDate = () => {
    if (props?.data?.validation) {
      if (props?.data?.validation?.fromDate) {
        return props?.data?.validation?.fromDate ?? "";
      } else if (
        props?.data?.validation?.toggleSelectedData === "Hide Past Dates"
      ) {
        return moment(new Date()).format("YYYY-MM-DD") ?? "";
      }
    }
  };

  const maximumDate = () => {
    // Hide Future Dates
    if (props?.data?.validation) {
      if (props?.data?.validation?.toDate) {
        return props?.data?.validation?.toDate ?? "";
      } else if (
        props?.data?.validation?.toggleSelectedData === "Hide Future Dates"
      ) {
        return moment(new Date()).format("YYYY-MM-DD") ?? "";
      }
    }
  };

  React.useEffect(() => {
    if (props?.data?.validation) {
      if (props?.data?.validation?.currentDate?.length > 0) {
        props.onChange(props?.data?.validation?.currentDate);
      }
    }
    // eslint-disable-next-line
  }, []);

  //
  return (
    <div>
      {getLabel(props)}
      <TextField
        fullWidth
        id={props.id}
        // label={getLabel(props)}
        type={props.type}
        // defaultValue={props?.data?.validation?.currentDate ?? props.defaultValue}
        value={props.value ?? ""}
        className={classes.textField}
        InputLabelProps={{
          shrink: false,
        }}
        inputProps={{
          readOnly: props.isReadonly ?? false,
          min: minimunDate(),
          max: maximumDate(),
        }}
        size="small"
        onChange={(e) => props.onChange(e.target.value)}
        variant={"outlined"}
        disabled={props?.disabled}
        error={
          props?.errorValidation && props?.errorValidation?.error
            ? props?.errorValidation?.error
            : false
        }
        helperText={
          props?.errorValidation && props?.errorValidation?.errorMessage
            ? props?.errorValidation?.errorMessage
            : ""
        }
      />
    </div>
  );
};
DateTime.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  isReadonly: PropTypes.bool,
};
DateTime.defaultProps = {
  label: "label",
  //   variant: "outlined",
  defaultValue: null,
  type: "date",
};
export default DateTime;
