import dotenv from "dotenv";
dotenv.config();

let Config = {};

Config.api_url = `${process.env.REACT_APP_BASE_URL_PROTOCAL}://${process.env.REACT_APP_BASE_URL_HOST}:${process.env.REACT_APP_BASE_URL_PORT}/api/v1`;
Config.database = process.env.REACT_APP_DB;
Config.arango_api_url = process.env.REACT_APP_ARANGO_DOMAIN_NAME;
Config.qdm_admin_url = process.env.REACT_APP_QDM_ADMIN_ENDPOINT;

export default Config;
