import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  NotFound,
  Login,
  ManageForms,
  FormConfigurator,
  ForgotPassword,
} from "./../screens";

import PrivateRoute from "./privateRouter";
import { Routes } from "./routes";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/">
          <Redirect to={Routes.home} />
        </Route> */}
        {/* 1. Home Route */}
        {/* <Route exact path={Routes.home} component={Login} /> */}

        {/* <Route exact path={Routes.world_clock} component={WorldClock} /> */}

        {/* 2. Auth Routes */}
        {/* <Route path={Routes.login} component={Login} /> */}

        {/* from Below */}
        {/* 2.1 forgot password */}
        {/* <Route path={Routes.forgot_password} component={ForgotPassword} /> */}

        {/* 5. Manage Form Router */}
        {/* 5.1 Manage Form */}
        <Route exact path={Routes.home}>
          <ManageForms />
        </Route>
        {/* <PrivateRoute exact path={Routes.manage_forms}>
          <ManageForms />
        </PrivateRoute> */}

        {/* 5.2 Form Configurator */}
        <PrivateRoute exact path={Routes.manage_form_detail}>
          <FormConfigurator />
        </PrivateRoute>

        {/* 16. Home Page */}
        <PrivateRoute exact path={Routes.home_page}>
          <ManageForms />
        </PrivateRoute>

        {/* For unknow/non-defined path */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
