//Providing the AuthContext from /src/context which is used in the /src/App.js.

import React from "react";
import { LinearProgress } from "@material-ui/core";
import { NetworkCall } from "./networkcall";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";
import { actions } from "form-binders";
import { connect } from "react-redux";
import Config from "./config";
import axios from "axios";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.updateApplicationVersion();
    this.refreshAPI();
    this.checkapi();

    this.logOutCheckInCrossBrowser();
  }
  checkapi = () => {
    debugger;
    let urlParams = new URLSearchParams(window.location.search);
    let metadataIdFromURL = urlParams?.get("metadata_id");
    let metaDataId = localStorage.getItem("metaDataId");
    let tokenFromUrl = urlParams.get("token");
    let token = localStorage.getItem("token");
    if (!(tokenFromUrl && metadataIdFromURL)) {
      window.location.replace(Config.qdm_admin_url);
    } else if (!(metaDataId && token)) {
      localStorage.setItem("metaDataId", metadataIdFromURL);
      localStorage.setItem("token", tokenFromUrl);
    } else if (tokenFromUrl !== token) {
      localStorage.setItem("token", tokenFromUrl);
    } else if (metadataIdFromURL !== metaDataId) {
      localStorage.setItem("metaDataId", metadataIdFromURL);
    }
  };
  logOutCheckInCrossBrowser = () => {
    window.addEventListener("storage", (e) => {
      if (e.key === LocalStorageKeys.authToken && e.oldValue && !e.newValue) {
        alert("You have been logged out, Click ok to Reload");
        window.location.reload();
      }
    });
  };

  // setDBFunc = async () => {
  //   let urlParams = new URLSearchParams(window.location.search);
  //   let metadataIdFromURL = urlParams?.get("metadata_id");
  //   console.log("metadataIdFromURL", metadataIdFromURL);
  //   let tokenFromUrl = urlParams.get("token");
  //   if (tokenFromUrl && metadataIdFromURL) {
  //     var data = JSON.stringify({
  //       db_name: "ATP_Metadata_Dev",
  //       entity: "projectvstools",
  //       filter: "projectvstools.metadataid == '" + metadataIdFromURL + "'",
  //       return_fields: "DOCUMENT(projectvstools.projectid)",
  //     });

  //     var config = {
  //       method: "post",
  //       url: "https://arangodbservice.dev.ainqaplatform.in/api/read_documents",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     let res = await axios(config);

  //     let dbname = res?.data?.result?.[0]?.projectdbname ?? "ATP_Metadata_Dev";
  //     localStorage.setItem("DBNAME", dbname);
  //     localStorage.setItem("payload", tokenFromUrl);
  //   } else {
  //     let dbname = "ATP_Metadata_Dev";
  //     localStorage.setItem("DBNAME", dbname);
  //     localStorage.setItem("payload", tokenFromUrl);
  //   }
  // };

  refreshAPI = async () => {
    debugger;
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      await Promise.resolve(this.props.REFRESH())
        .then((res) => {
          debugger;
          this.setState({ loading: false }, () => {
            if (res?.payload?.error) {
              localStorage.setItem(LocalStorageKeys.authToken, "");
            } else {
              let data = res?.payload?.data;
              localStorage.setItem(
                LocalStorageKeys.authToken,
                data?.token_type + " " + data?.access_token
              );
            }
          });
        })
        .catch((err) => {
          console.log("App Auth:", err);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  updateApplicationVersion = () => {
    debugger;
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  render() {
    let { loading } = this.state;

    return <>{loading ? <LinearProgress /> : this.props.children}</>;
  }
}

const mapStateToProps = (state) => {
  console.log(state?.authSlice?.login, "AuthStates...");
  return {
    auth: state?.authSlice?.login,
  };
};
// const mapStateToProps = (state) => ({
//   auth: state?.authSlice?.login,
// });

export default connect(mapStateToProps, actions)(AppAuth);
