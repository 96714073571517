import moment from "moment";
import { Routes } from "../router/routes";

export const giveMeRoleDataSecurity = (data) => {
  let roleDataSecurity = {
    country: [],
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
    level6: [],
    level7: [],
    level8: [],
    level9: [],
    level10: [],
    level11: [],
    level12: [],
    disease: [],
    form: [],
    myRole: [],
    isOnlyNotifier: false,
  };

  let mapped_roles = [data?.mapped_roles?.[0] ?? {}];

  for (const key in mapped_roles) {
    const role = mapped_roles[key];

    //Checking is Notifier Only
    if (
      role?.previlages?.notify?.length > 0 &&
      role?.previlages?.notification?.length > 0 &&
      role?.previlages?.Caseverification?.length === 0 &&
      role?.previlages?.caseRegistration?.length === 0 &&
      role?.previlages?.investigation?.length === 0
    ) {
      roleDataSecurity.isOnlyNotifier = true;
    }

    roleDataSecurity.myRole.push(role.role_id);

    //Country
    if (role.data_security?.country?.value) {
      roleDataSecurity.country.push(role.data_security.country.value);
    }

    //Disease & Forms
    role.data_security?.mappingTable?.forEach((mapTable) => {
      //Disease
      if (
        mapTable.disease?.value?.toLowerCase() !== "all" &&
        roleDataSecurity?.disease?.[0]?.value?.toLowerCase() !== "all"
      ) {
        roleDataSecurity.disease.push(mapTable.disease?.value);
      } else {
        roleDataSecurity.disease = ["all"];
      }

      //Forms
      if (
        mapTable.form?.value?.toLowerCase() !== "all" &&
        roleDataSecurity?.form?.[0]?.value?.toLowerCase() !== "all"
      ) {
        roleDataSecurity.form.push(mapTable.form?.value);
      } else {
        roleDataSecurity.form = ["all"];
      }
    });

    //SelectedLevel
    role?.mapped_location?.forEach((loc) => {
      Object.keys(loc)?.forEach((mapLocKey) => {
        if (
          loc[mapLocKey].value?.toLowerCase() !== "all" &&
          roleDataSecurity?.[mapLocKey]?.[0]?.value?.toLowerCase() !== "all"
        ) {
          roleDataSecurity[mapLocKey].push(loc[mapLocKey].value);
        } else {
          roleDataSecurity[mapLocKey] = ["all"];
        }
      });
    });
  }

  return roleDataSecurity;
};

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val[valueKey],
      label: val[labelKey],
    };
  });
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

/**
 * The below function will help in decide the component type
 */

export const ComponentType = (type) => {
  const optionComponentsList = [
    "smart skip",
    "popup_switch",
    "list_text",
    "custom_multi_select_tag",
    "input_text",
    "input_textarea",
    "input_date_picker",
    "input_number",
    "hours_minutes_range",
    "label",
    "note",
    "location",
    "custom_table",
    "custom_table_2",
    "mobile_no",
    "location_level",
    "date_of_birth",
  ];
  if (optionComponentsList.includes(type)) {
    return false;
  } else {
    return true;
  }
};

export const giveMeReplacedNotifiyTodoText = (text = "", data = {}) => {
  //Replacing Date
  text = text.replace("<<Date>>", moment(data?.created_at).format("lll"));

  //Replace Form Name
  text = text.replace(
    "<<Form Name>>",
    data?.form_detail?.form_name ?? "a form"
  );

  let patient_name = `${
    data?.patient_detail?.patient_name?.title?.label ?? ""
  } ${data?.patient_detail?.patient_name?.first_name ?? ""} ${
    data?.patient_detail?.patient_name?.last_name?.[0] ?? ""
  }`;
  //Replace Patient Name
  text = text.replace(
    "<<Patient Name>>",
    patient_name.trim().length > 0 ? patient_name : "a patient"
  );

  //Replace Notifier Name
  text = text.replace(
    "<<Notifier User Name>>",
    data?.created_by_detail?.first_name ?? "A notifier"
  );

  //Replace Verifier Name
  text = text.replace(
    "<<Verifier User Name>>",
    data?.created_by_detail?.first_name ?? "A verifier"
  );

  //Replace Case Registor Name
  text = text.replace(
    "<<Case Registor User Name>>",
    data?.created_by_detail?.first_name ?? "A case registor"
  );

  //Replace Investigator Name
  text = text.replace(
    "<<Investigator User Name>>",
    data?.created_by_detail?.first_name ?? "A investigator"
  );

  return text;
};

export const giveMeRouteBasedOnTodoNotify = (data) => {
  if (data.notify_type) {
    //Redirecting URL for Notifier
    if (["1", "5", "6", "7", "8"].indexOf(data.notify_type) > -1) {
      return `${Routes.edit_form_notify_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Verifier
    if (["2", "9", "10", "11"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_verify_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Case Regsitor
    if (["3", "12"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_register_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Investigator
    if (["4"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_investigator_parent}${data.form_id}/${data.entry_id}`;
    }
  }
  return "";
};

export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};
