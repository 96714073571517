//Component To Render

import React from "react";
import { FormComponents } from "../../utils";
import {
  TabSelect,
  Select,
  Input,
  DateTimePickers,
  CustionAddText,
  Notify,
  CheckBox,
  RadioButton,
  Location,
  CustomTableFormRender,
  CustomTableFixedRowFormRender,
  Mobile,
  LocationLevel,
  DOB,
} from "../../components";

export const ComponentToRender = ({
  index,
  data,
  value,
  isReadyOnly = false,
  onChange,
  isrequired,
  errorValidation,
  disabled,
}) => {
  switch (data.ui_component) {
    case FormComponents.single_tab_select:
      return (
        <TabSelect
          label={data?.question}
          value={[value]}
          items={data?.question_options?.map((_) => _) ?? []}
          onClick={(value) => onChange(index, data, value)}
          isReadyOnly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.multi_tab_select:
      return (
        <TabSelect
          label={data?.question}
          value={value}
          items={data?.question_options?.map((_) => _) ?? []}
          onClick={(value) => onChange(index, data, value)}
          multiple={true}
          isReadyOnly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.multi_select_tag:
      let allValues = value ? value.map((_) => _.value) : [];

      return (
        <Select
          label={data?.question}
          multiple
          value={value ? value : []}
          handleselect={(value) => onChange(index, data, value)}
          option={data?.question_options
            ?.map((_) => {
              return {
                value: _,
                label: _,
              };
            })
            .filter((_) => allValues.indexOf(_.value) === -1)}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.select_modal_dropdown:
      return (
        <Select
          label={data?.question}
          value={value ? [value] : []}
          handleselect={(value) => onChange(index, data, value)}
          option={data?.question_options?.map((_) => {
            return {
              value: _,
              label: _,
            };
          })}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.input_number:
      return (
        <Input
          type={"number"}
          value={value}
          onChange={(value) => onChange(index, data, value)}
          label={data?.question}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          data={data}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.input_text:
      return (
        <Input
          value={value}
          onChange={(value) => onChange(index, data, value)}
          label={data?.question}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          data={data}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.input_textarea:
      return (
        <Input
          value={value}
          onChange={(value) => onChange(index, data, value)}
          multiline
          label={data?.question}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          data={data}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.input_date_picker:
      return (
        <DateTimePickers
          multiline
          value={value?.length > 0 ? value : data?.validation?.currentDate}
          onChange={(value) => onChange(index, data, value)}
          label={data?.question}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          data={data}
          errorValidation={errorValidation ?? {}}
          disabled={
            disabled ? disabled : data?.validation?.currentDate ?? false
          }
        />
      );

    case FormComponents.custom_multi_select_tag:
      return (
        <CustionAddText
          label={data?.question}
          options={value ? value : []}
          addTag={(value) => onChange(index, data, value)}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.label:
      debugger;
      return <Notify text={data?.question} />;

    case FormComponents.note:
      return <Notify text={data?.question} notes />;

    case FormComponents.popup_switch:
      return (
        <TabSelect
          label={data?.question}
          value={[value]}
          items={["Yes", "No"]}
          onClick={(value) => onChange(index, data, value)}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.check_box:
      return (
        <CheckBox
          heading={data?.question}
          option={data?.question_options?.map((_) => {
            return {
              value: _,
              label: _,
            };
          })}
          direction="row"
          checked={value?.length > 0 ? value : []}
          handleChange={(value) => onChange(index, data, value)}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );
    case FormComponents.radio_button:
      return (
        <RadioButton
          heading={data?.question}
          option={data?.question_options?.map((_) => {
            return {
              value: _,
              label: _,
            };
          })}
          direction="row"
          value={value}
          handleChange={(value) => onChange(index, data, value)}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );
    case FormComponents.location:
      return (
        <Location
          value={value}
          label={data?.question}
          handleChange={(value) => onChange(index, data, value)}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );
    case FormComponents.custom_table:
      return (
        <CustomTableFormRender
          heading={data?.question}
          data={data}
          handleChange={(value) => onChange(index, data, value)}
          tableData={value?.tableData?.length === 0 ? [] : value?.tableData}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? []}
          disabled={disabled ?? false}
        />
      );
    case FormComponents.custom_table_2:
      return (
        <CustomTableFixedRowFormRender
          heading={data?.question}
          data={data}
          handleChange={(value) => onChange(index, data, value)}
          tableData={value?.tableData?.length === 0 ? [] : value?.tableData}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? []}
          disabled={disabled ?? false}
        />
      );
    case FormComponents.mobile_no:
      return (
        <Mobile
          value={value}
          label={data?.question}
          handleChange={(value) => onChange(index, data, value)}
          type={"number"}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          data={data}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );

    case FormComponents.location_level:
      return (
        <LocationLevel
          label={data?.question}
          value={value}
          handleChange={(value) => onChange(index, data, value)}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );
    case FormComponents.date_of_birth:
      return (
        <DOB
          value={value}
          onChange={(value) => onChange(index, data, value)}
          label={data?.question}
          isReadonly={isReadyOnly}
          isrequired={isrequired}
          data={data}
          errorValidation={errorValidation ?? {}}
          disabled={disabled ?? false}
        />
      );
    default:
      return <></>;
  }
};
