import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { CardListLoading } from "./loading";

export const CardListLoader = (props) => {
  const { count } = props;

  return (
    <div>
      <Grid container direction="column" spacing={1}>
        {Array.apply(null, Array(count)).map((value, index) => (
          <Grid key={index} item>
            <CardListLoading />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

CardListLoader.propTypes = {
  count: PropTypes.number,
};

CardListLoader.defaultProps = {
  count: 1,
};
